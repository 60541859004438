@import '../../../../../../admin/components/styles/AdminPanel.scss';
@import '../../../../../../admin/components/dashboard/styles/AdminDashboardStyles.scss';

.training-view-dialog {
    & #admin-dashboard-container > * {
        border: 0 !important;
        box-shadow: none !important;
    }
    
    .training-header.edit-training span {
        display: none;
    }
}
