#share-screen-dialog-container {
    #screen-preview {
        width: 100%;
        height: 90vh;
        position: relative;
        & > div {
            width: 100% !important;
            height: 84vh !important
        }
    }

    & #SHARE_SCREEN-title {
        padding: 0 16px !important;
        text-align: center;
    }

    .btn-share {
        padding: 8px;
        border-radius: 4px;
        margin-bottom: 8px;
        transition: all 0.2s;

        &:hover {
            opacity: 0.8;
        }
    }
}