@import './../variables';

.btn-default {
    background-color: $btn-control-default;
    color: $txt-control-default;
    &:hover {
        background-color: $btn-control-default-hover;
        color: $txt-control-default-hover;
    }
}

.btn-primary {
    background-color: $btn-control-primary !important;
    color: $txt-control-primary-color !important;
    font-size: 14px !important;
    &:hover {
        background-color: $btn-control-primary-hover !important;
        color: $txt-control-primary-color-hover !important;
    }
}

.btn-secondary {
    background-color: $btn-control-secondary !important;
    color: $txt-control-secondary-color !important;
    &:hover {
        background-color: $btn-control-secondary-hover !important;
        color: $txt-control-secondary-color-hover !important;
    }
}

.btn-contrast {
    background-color: $btn-control-contrast !important;
    color: $txt-control-contrast-color !important;
    &:hover {
        background-color: $btn-control-contrast-hover !important;
        color: $txt-control-contrast-color-hover !important;
    }
}