.App {
    min-height: 100%;
    text-align: center;
    background: url(/icons/main-background-new-design-rvm.png) no-repeat bottom center, linear-gradient(to bottom, rgb(0, 68, 130), rgb(25, 113, 162) 100%);
    background-size: contain;
}

/* #root {
    height: auto;
} */

.App-container {
    position: relative;
}

.logo {
    margin-left: -70px;
}
.logo > img {
    width: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.full-cell {
    margin-left: 6px;
    margin-top: -9px;
    font-weight: 600;
    position: absolute;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.MuiListItem-button:hover {
    background-color: #f5e02e !important;
}

.button-clear {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
    cursor: pointer;
}

.button-blue {
    height: 44px;
    padding: 6px 12px 6px 13px;
    border-radius: 5px;
    border: solid 2px #296daa;
    background-image: linear-gradient(to bottom, rgb(83, 166, 213), #296daa);
    font-size: 19px;
    font-weight: 500;
    line-height: 1.68;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.h1-ffa {
    position: relative;
    margin-left: -249px !important;
}

.maxWidth-force {
    min-width: 100%;
}

.analyse-button {
    position: absolute;
    margin-top: -35px;
    margin-left: 70px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.financial-container {
    position: absolute;
    left: 41px;
    margin-top: 160px;
}

.page-title {
    padding-top: 78px;
    margin-top: 42px;
    font-size: 21px;
    margin-bottom: 0;
    margin-left: -6px;
    padding-top: 45px;
    position: absolute;
    left: 68px;
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--white);
}

.page-title1 {
    left: 24px;
    position: absolute;
    text-align: left;
    width: 316px;
}

.in-service-cont {
    margin-top: 225px !important;
}

.facilitator-span {
    margin-left: -43px;
}

.bottom-div {
    position: absolute;
    margin-top: -215px;
    left: 44px;
}

.bottom-div1 {
    position: absolute;
    top: calc(50% - 115px);
    left: 59px;
}

.bottom-div2 {
    position: absolute;
    top: calc(33vh - 13px);
    left: 19px;
}

.icon-add {
    filter: invert(114%) sepia(140%) saturate(10%) hue-rotate(89deg) brightness(119%) contrast(119%);
    width: 17px;
}

#video-container > div {
    transition: border-color 0.2s ease-in-out;
    position: relative;
    z-index: 1;
    background-color: #0D4171;
}

.bottom-row-financial {
    position: relative;
    display: inline-flex !important;
    bottom: 0;
    right: -365px;
}

body #video-container > div.talking {
    border-color: #ebc429 !important;
}

.react-toast-notifications__container {
    z-index: 10000 !important;
    font-size: 17px;
}

ul[aria-labelledby="stage-select"] .MuiMenuItem-root:nth-of-type(odd) {
    background-color: #ebebeb;
}

ul[aria-labelledby="stage-select"] .MuiListItem-root.Mui-selected:hover {
    background-color: #f5e02e !important;
}

ul[aria-labelledby="stage-select"] .MuiListItem-root.Mui-selected {
    background-color: #c2af08;
}

ul[aria-labelledby="stage-select"] .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: unset !important;
}

#video-container > div > span {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    z-index: 999;
    padding: 1px 10px;
    background-color: #1b1c20;
    font-size: 14px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
    max-width: 137px;
    box-sizing: border-box;
    text-overflow: ellipsis;
}

#video-container > div > span.rm,
#video-container > div > span.trainer {
    bottom: initial;
    left: initial;
    top: 0;
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    color: #1b1c20;
    background-color: #fff;
}

#video-container > div > span.rm {
    text-transform: uppercase;
}

#video-container > div > span.trainer {
    background-color: #ebc429;
}

/* Map Assignment */
.ma-toggle-button {
    min-width: 105px;
    padding: 6px 0 6px 30px;
    border: 0;
    outline: 0;
    color: #144b7e;
    font-size: 15px;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.ma-toggle-button.hidde-map-icon {
    background: url("/icons/icons-show.png") no-repeat left 6px;
}

.ma-toggle-button.show-map-icon {
    background: url("/icons/icons-hide.png") no-repeat left center;
}

.hidden-map {
    position: relative;
}

.hidden-map::after {
    content: '';
    display: inline-block;
    position: absolute;
    background-image: linear-gradient(172deg, #c2c2c2 6%, #8f8f8f 96%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 3px solid #1b1c20;
    box-sizing: border-box;
}

.ma-table-container-0 {
    margin-right: 25px;
}

.ma-table-container-1 > div:first-child {
    text-align: right;
}

/* END Map Assignment */

/* Dummy menu dropdown TEMP fixes */
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    top: 48px !important;
    z-index: 9999;
}

[role="presentation"] {
    z-index: 99;
}

.MuiPopover-root {
    z-index: 99999 !important;
}

.MuiDrawer-root {
    z-index: 9999 !important;
}
/* END Dummy menu dropdown TEMP fixes */

.separator {
    border: 1px solid #dee1e3;
    height: 17px;
    margin: 0 15px;
}

.bottom-shadow {
    background: url("/icons/BoardShadow.png") no-repeat left center;
    background-size: contain;
    height: 50px;
    top: calc(20px + 684px);
    left: calc(50% - 235px);
    width: 705px;
    position: absolute;
}

/* md */
@media only screen and (max-width: 960px) {
    
}

/* lg */
@media only screen and (max-width: 1280px) {
    .game-match,
    .game-no-match,
    .game-inside {
        width: 40px !important;
        height: 40px !important;
    }
}

/* xl */
@media only screen and (max-width: 1920px) {
    .page-title {
        padding-top: 20px; 
    }
    .page-title.page-title1 {
        padding-top: 0
    }
}

@media only screen and (min-width: 1920px) {
    .page-title {
        padding-top: 45px; 
    }
}

/* md */
@media only screen and (max-height: 850px) {
    .game-num-top {
        width: 40px !important;
        margin: 0 3px !important;
    }

    .game-num-left {
        height: 46px !important;
    }

    .game-match,
    .game-no-match,
    .game-inside {
        width: 40px !important;
        height: 40px !important;
    }
 
    .makeStyles-root-136 {
        min-height: 710px;
    }
}

@media only screen and (max-height: 650px) {
    
    .game-num-top {
        width: 30px !important;
        margin: 0 3px !important;
        font-size: 16px !important;
    }
    
    .game-num-left {
        height: 36px !important;
        font-size: 16px !important;
    }

    .game-match,
    .game-no-match,
    .game-inside {
        width: 30px !important;
        height: 30px !important;
    }
}

@media only screen and (max-width: 1700px) {
    .App {
        height: auto;
    }

    #open_in_service_dialog .game-match,
    #open_in_service_dialog .game-no-match,
    #open_in_service_dialog .game-inside {
        width: 30px !important;
        height: 30px !important;
    }

    #open_in_service_dialog .game-num-top {
        width: 30px !important;
    }

    #open_in_service_dialog .game-num-left {
       height: 36px !important;
       transform: translateY(2px);
    }   
}

@media only screen and (max-width: 1440px) {

    #open_in_service_dialog .map-memorization-grid-container{
        transform: translateX(-118px);
    }

    #open_in_service_dialog table td {
        width: 22px;
        height: 22px;
    }
    
    .full-cell {
        margin-left: 4px;
    }
}

@media only screen and (max-width: 1280px) {

    .page-title {
        padding-top: 0;
        margin-top: 5px;

    }

    .page-title + div + .bottom-div1 {
        top: calc(50% - 70px);
    }

    #open_in_service_dialog .map-memorization-grid-container{
        transform: translate(-118px, 12px);
    }

    #open_create_player_dialog {
        width: 750px !important;
        height: 450px !important;
    }

    #open_in_service_dialog .game-match,
    #open_in_service_dialog .game-no-match,
    #open_in_service_dialog .game-inside {
        width: 28px !important;
        height: 28px !important;
    }

    #open_in_service_dialog .game-num-top {
        width: 28px !important;
    }

    #open_in_service_dialog .game-num-left {
        height: 34px !important;
        transform: translateY(3px);
    }   
}

.participants-visible + div + div + div.video-controls-container,
.chat-visible + div + div.video-controls-container {
    right: 280px;
    box-sizing: border-box;
    border-right: 1px solid #085898;
}

/* .participants-visible + div + div + div.video-controls-container #admin-rooms-container,
.chat-visible + div + div.video-controls-container #admin-rooms-container {
    right: 390px; 
} */

.MuiList-root.MuiMenu-list.MuiList-padding li {
    z-index: 999999999 !important;
}

.participants-visible + div + div + div.video-controls-container.opened #admin-rooms-container,
.chat-visible + div + div.video-controls-container.opened #admin-rooms-container {
    right: 480px;
}

.App-container:after,
main:after,
.game-component-container:after,
main > :first-child:after {
    content: "";
    display: table;
    clear: both;
}

.disabled-video {
    border: solid 3px #aeaeb0 !important;
}

.disabled-video:before {
    background-color: rgb(80, 136, 162);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    background-image: url('/icons/fill-1.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
}

.disabled-video video {
    display: none;
} 
