@import '../../../../styles/variables';
@import '../../../../styles/styles.scss';

.opened {
    #admin-rooms-container {
        right: 200px;
    }
}

.rooms-tooltip {
    div {
        background-color: #013d6d;
        font-size: 15px;
        font-weight: bold;
    }
} 

.not-found {
    display: none !important;
}

#admin-rooms-container {
    display: flex;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    align-items: center;
    width: 70px;
    box-sizing: border-box;
    background-color: #013d6d;
    top: 50%;
    transform: translateY(-50%);
    right: 110px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    position: fixed;
    z-index: 999999;
    flex-direction: column;
    transition: all 0.5s;
    border-right: 1px solid $section-border-color;
    padding-bottom: 12px;

    & > h4 {
        margin: 12px 0;
    }

    & button {
        padding: 0 !important;
        width: 50px;
        min-width: auto !important;
        height: 35px;
        cursor: pointer;
        outline: 0;
        background-color: #17609b;
        font-size: 19px;
        font-weight: 500;
        color: #fff;
        border: solid 3px transparent;
        &:not(:last-child) {
            margin-bottom: 5px;
        }

        &.active,
        &:hover {
            border-color: #fff !important;
        }

        &.calling {
            position: relative;
            border-color: #ebc429;

            &:after {
                content: '';
                width: 30px;
                height: 30px;
                position: absolute;
                right: -5px;
                bottom: -5px;
                background: url(/icons/admin/hand-icon.svg) no-repeat center center;

            }
        }
    }
}