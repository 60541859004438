* {
  --mid-blue: #296daa;
  --dark: #1b1c20;
  --white: #ffffff;
  --grey-blue: #62b2cf;
  --pale-purple: #aeaeb0;
}

#admin-dashboard-container {
  font-family: "Roboto";
  margin: 0;
  display: flex;
  flex-flow: row wrap;

  & > * {
    flex: 50%;
    border-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    border: solid 3px #ebc429;
    &:first-child {
      margin-right: 0;
    }
  }

  & > .MuiPaper-root {
    background-image: linear-gradient(to bottom, #e9e9e9, #ffffff 41%);
    min-height: 83vh;
    flex: 1 1;
    &:first-of-type {
      margin-right: 45px;
    }
    &:last-of-type {
      margin-right: 85px;
    }
  }

  .normal-button {
    padding: 6px 6px 5px 8px;
    border-radius: 5px;
    border: solid 2px var(--pale-purple);
    background-image: linear-gradient(to bottom, #edf0f2, var(--pale-purple));
    color: var(--dark);
    cursor: pointer;

    &:hover {
      background-image: linear-gradient(to bottom, var(--pale-purple), #edf0f2);
    }
  }

  .fin-header-div {
    display: none;
  }

  .fin-report {
    border: unset !important;
    max-width: 0 !important;
    min-width: unset !important;
    background: unset !important;
    margin-left: 227px;
    margin-top: -316px;
    margin-bottom: 83px;
  }

  #final-text {
    background-color: #296daa !important;
    .final-text-row {
      color: white !important;
    }
  }
}

.MuiTab-wrapper {
  font-size: 17px;
  font-family: "Roboto";
  font-weight: bold;
  text-transform: capitalize;
}

.btn-back {
  cursor: pointer;
}

.training-header {
  position: relative;
}
.trainings-scroll {
  overflow-y: scroll !important;
  height: 90vh !important;
  max-height: 100vh !important;
}
.trainings,
.training-header {
  border-bottom: 5px solid #296daa;
  padding-bottom: 18px;
  font-family: "Roboto";
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 99%;
  display: block;
  text-align: center;
  color: rgb(0, 0, 0);
  margin-top: 0px;
  & > span {
    width: 21px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
    display: none;
    background: url(/icons/admin/close.svg) no-repeat center center;
    &:hover {
      background: url(/icons/admin/close_hover.svg) no-repeat center center;
    }
  }

  &.edit-training {
    & > span {
      display: inline-block;
    }
  }
}

.admin-form-container {
  .two-columns:nth-of-type(3) {
    margin-top: 329px !important;
    margin-left: 146px;
  }
  .two-columns1 {
    display: flex;
    margin-top: 30px;
  }
  .date-container {
    margin-top: 20px !important;
    margin-left: 41px !important;
  }
  .groups {
    margin-top: 7px;
    margin-right: 12px;
  }
}

.training-details-history {
  .new-training {
    width: 100%;
    height: 5px;
    margin: 43px 20px 0 0px;
    background-color: var(--mid-blue);
  }
  .MuiPaper-root {
    background-color: unset !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: unset !important;
  }
  .trainings {
    margin-top: -13px;
  }
  .training-name {
    width: 172px;
    height: 24px;
    margin: 0 3px 8px 23px;
    font-family: Roboto;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black;
  }
  .left-align1 {
    margin-left: 31px;
    display: inline-flex;
    min-width: 240px !important;
    font-family: Roboto;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
  }
  .span-groups {
    margin-left: 10px;
  }
  .span-time {
    width: 151px;
    height: 23px;
    position: absolute;
    margin-top: -19px;
    margin-left: 21px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    color: black;
  }
  .financial-rep-div {
    margin-left: 23px;
    width: 124px;
    height: 20px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    position: absolute;
    color: var(--dark);
  }
  .acc-subtitle {
    margin-left: 26px;
    margin-top: 1px;
    position: absolute;
    color: #838487;
    font-weight: 500;
    font-size: 15px;
  }
}

.active-training {
  box-shadow: 0 2px 4px 0 #aeaeb0;
  background-color: #fff;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.displayRow {
  display: table-row !important;
}

.displayRowRect {
  display: table-row !important;
  background-color: #fff3c5;
}

.displayCell {
  display: table-cell !important;
  border-spacing: 5px;
  border-collapse: separate;
  padding: 10px !important;
  height: 50px;
  line-height: 28px;
  padding-left: 20px !important;
}

.displayTable {
  width: 100%;
  display: table;
  border-collapse: separate;
  // border-spacing: 10px 1px;
}

.trainDebriefQuestion {
  font-weight: bold;
  width: 86px;
  height: 19px;
  font-family: Roboto;
  font-size: 16px;
}

.trainDebriefTeam {
  display: inline-flex;
  position: relative;
  width: 70px;
  height: 16px;
  padding: -4px;
  // margin: 19px 10px 50px 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.trainDebriefAnswer {
  width: 100px;
  height: 48px;
  margin: 15px 0px 18px -28px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.trainingDebriefQuestionRact {
  background-color: #fff3c5;
  // width: 586px;
  height: 30px;
  margin: 0 0 17px;
  padding: 8px 15px 8px 8px;
}

.team1Badge {
  width: 29px;
  position: absolute;
  height: 29px;
  margin-top: -4px;
}

.team2Badge {
  width: 29px;
  position: absolute;
  height: 29px;
  margin-top: -63px;
  margin-left: 24px !important;
}

.team3Badge {
  width: 29px;
  position: absolute;
  height: 29px;
  margin-top: -62px;
  margin-left: 3px;
}

.questionSeparator {
  border-top: 1px solid lightblue;
  margin-left: 3px;
  width: auto;
}

.final-th {
  color: #101773 !important;
}

.final-total {
  font-size: 15px !important;
  font-weight: 900 !important;
}

.displayTeams {
  display: flex;
  flex-wrap: wrap;
  margin-right: 130px;
}

.participate {
  margin-bottom: 9px;
}

.userParticipated {
  margin-bottom: 9px;
  color: green;
}

.userNotParticipated {
  margin-bottom: 9px;
  color: red;
}
.tal {
  text-align: left;
}

.team {
  flex: 1 0 45%;
  margin: 20px;
  // height: 200px;
}

.teamName {
  margin-top: 14px;
  margin-bottom: 14px;
  font-family: Roboto;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.team-name {
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.teamMembers {
  margin-bottom: 14px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
