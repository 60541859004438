html,
#root {
    height: 100%;
}

body {
    overflow-x: hidden;
    margin: 0;
    height: 100%;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-image: linear-gradient(to bottom, rgb(0, 68, 130), rgb(25, 113, 162) 100%); */
    background: linear-gradient(to bottom, rgba(135, 201, 229, 0.5) 1%, rgba(135, 201, 229, 0) 74%) no-repeat;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Video Styles  */
.OT_root:nth-child(odd) {
    left: 0 !important;
}

.OT_root:nth-child(even) {
    right: 0 !important;
}

/* Loading indicator */
#loading-indicator.loading {
    position: fixed;
    top: 0;
    background-image: linear-gradient(to bottom, rgba(135, 201, 229, 0.3), rgba(135, 201, 229, 0) 74%);
    background-color: #fff;
    height: 100%;
    width: 100%;
    z-index: 999999;
    overflow: hidden;
}
#loading-indicator.loading:after {
    content: "";
    display: inline-block;
    width: 270px;
    height: 200px;
    background: url("/icons/loading-indicator.gif") no-repeat center center;
    position: absolute;
    left: calc(50% - 135px);
    top: calc(50% - 100px);
}

#loading-indicator.loading + div#root {
    overflow: hidden;
}

.hidden {
    display: none !important;
}