@keyframes blinker {
    from {
        fill: #deb202;
    }
    to {
        fill: #fff;
    }
}

.blinking-yellow-fill {
    & path {
        animation-name: blinker;
        animation-iteration-count: infinite;
        animation-duration: 1s;
    }
}
