.main-div-1 {
    position: absolute;
    margin-top: 140px;
    left: 26px;
    margin-bottom: 20px;
    max-width: 253px;
    color: black;
    height: 58px;
    top: 112px;
    font-size: 19px;
    font-weight: bold;
    padding-top: 10px;
    z-index: 5000;

    & button {
        margin-bottom: 8px;
        width: 252px;
        height: 32px;
    }
}

.in-service-page {
    .container-financial-st {
        margin-top: 80px;
    }
}