@import "../../../../styles/styles.scss";

.video-controls-container {
    display: flex;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    align-items: center;
    width: 70px;
    background-color: #013d6d;
    top: calc(50% - 143px);
    right: 0px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    position: fixed;
    z-index: 999999;
    transition: all 0.5s;

    .devices-btn {
        font-size: 12px !important;
        margin-left: 36px;
        position: absolute;
        top: 17px;
        color: white;
        transform: rotate(-90deg);

        .svg-devices {
            filter: invert(60%) sepia(81%) saturate(100%) hue-rotate(161deg) brightness(132%) contrast(154%);
        }
    }

    .grid-audio-devices {
        display: block !important;
        position: fixed;
        z-index: 9999999;
        margin-left: -170px;
        width: 200px;
        border-radius: 5px;
        background: #2e9fe2;

        & > * {
            margin: 0;
            padding: 10px 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:first-child {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
            &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    .audio-dev {
        &:hover {
            background-color: #085898;
        }
    }

    .active-device {
        background-color: #085898;
    }

    & .trainer-section {
        display: none;
        padding: 14px 0;
        & .additional-text {
            padding: 0 15px;
            & + .additional-text {
                margin-top: 8px;
            }
            &:first-child {
                margin-bottom: 6px;
            }
            &:last-child {
                margin-top: 14px;
                button.text-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .short-text {
                        position: initial;
                    }
                }
            }
            &.pause-end-container {
                .button-clear {
                    justify-content: center;
                    span.icon {
                        width: 32px;
                        height: 32px;
                        padding: 0;
                    }
                }
            }
        }
        &.share-leave-btn-container {
            & .additional-text {
                &:first-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    & button {
        transition: all 0.3s;
        opacity: 1;
        &:hover {
            opacity: 0.5;
        }
        &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }

    &.video-controls-container-admin {
        & .trainer-section {
            display: flex;
        }

        top: 50%;
        transform: translateY(-50%);
        width: 110px;

        & .btns-group {
            .short-text {
                left: 45px;
            }
        }
        & .btns-group-inline {
            .short-text {
                left: 30px;
            }
            * button {
                justify-content: flex-start;
            }
        }
        .text-button {
            & .short-text {
                left: 8px !important;
            }
        }

        &.opened {
            width: 200px;
            & .pause-button {
                min-width: 81px;
            }
            & .resume-button {
                width: 200px;
                & .long-text.lng-text1 {
                    left: 29px !important;
                    top: 10px;
                }
            }
        }

        & .resume-button {
            width: 200px;
            & .long-text {
                left: 500px;
            }
        }
    }

    & span {
        font-size: 12px !important;
    }

    .btn-contrast {
        & span {
            font-size: 14px !important;
        }
    }

    .connected-users-label {
        display: inline-block;
    }

    .share-leave-btn-container {
        box-sizing: border-box;
    }

    &.opened {
        width: 170px;

        .app-participants-label {
            display: inline-block;
        }

        & .btns-container {
            & > span {
                transform: rotate(180deg);
            }
        }

        .open-close-btn {
            & button {
                transform: rotate(90deg);
            }
        }

        .additional-text {
            .long-text {
                left: 0px;
            }

            .short-text {
                position: absolute !important;
                left: 500px !important;
            }
        }

        .btns-group-inline {
            & button {
                justify-content: flex-start !important;
            }
        }

        .text-button {
            & .long-text {
                left: 15px !important;
            }
        }

        & .btns-group {
            .long-text {
                left: 45px;
            }
        }

        & .ppl .btns-group .long-text {
            left: 30px;
        }

        & .btns-group-inline {
            .long-text {
                left: 40px;
            }
        }

        .mic-button + .long-text {
            left: 65px;
        }
    }

    .btns-container {
        height: 100%;

        & > span {
            width: 11px;
            height: 24px;
            top: 46%;
            left: 12px;
            position: absolute;
            display: inline-block;
            cursor: pointer;
            transition: transform 0.5s;
            background: url(/icons/left-chevron.svg) no-repeat left center;
        }

        & > div {
            height: 100%;
        }
    }

    .open-close-btn {
        border-bottom: 1px solid $section-border-color;
        padding: 6px 8px;
        width: 100%;
        text-align: left;
        & button {
            transition: all 0.5s;
            transform: rotate(-90deg);
        }
    }

    .additional-text {
        overflow: hidden;
        .long-text {
            white-space: nowrap;
            transition: all 0.5s;
            width: 100%;
            display: inline-block;
            text-align: left;
            margin-left: 10px;
            left: 500px;
            position: absolute;
        }

        .short-text {
            white-space: nowrap;
            transition: all 0.5s;
            display: inline-block;
            position: absolute;
            left: 20px;
        }
    }

    .btns-group-inline {
        border-bottom: 1px solid $section-border-color;
        width: 100%;
        & > * {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 15px;

            & button {
                overflow: hidden;
                position: relative;
                border-radius: 3px;
                min-height: 32px;
                white-space: nowrap;
                width: 100%;
                background: $btn-control-default;
                align-items: center;
                display: flex;
                justify-content: center;

                &:hover {
                    background: $btn-control-default-hover;
                }

                & .long-text {
                    margin-left: 0;
                }

                & > span:first-child {
                    padding: 5px 0;
                }
                & span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    &.icon {
                        padding: 5px;
                    }
                }
            }
        }
    }

    .btns-group {
        border-bottom: 1px solid $section-border-color;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 12px 15px;

        .additional-text {
            & + .additional-text {
                margin-top: 10px;
            }
        }
        & > * {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            & button {
                position: relative;
                cursor: pointer;
            }

            & .short-text {
                position: absolute;
                left: 45px;
            }

            & .ppl .short-text {
                left: 30px;
            }

            &:hover {
                & .mic-button-all,
                & .mic-button,
                & .video-button,
                & .ppl-button,
                & .chat-button {
                    & path {
                        fill: $btn-control-default-hover;
                    }
                }
            }

            & button {
                & span {
                    display: inline-block;
                    height: 28px;
                    width: 25px;
                }
            }
        }
    }

    .ppl {
        &.participants-visible {
            border-radius: 3px;

            & path {
                fill: rgba(174, 174, 176, 0.5);
            }
        }
    }

    .chat {
        &.chat-visible {
            border-radius: 3px;

            & path {
                fill: rgba(174, 174, 176, 0.5);
            }
        }
    }

    .app-chat-label {
        color: white;
    }

    .call-trainer-container {
        & path {
            fill: #000;
        }
    }

    .text-button {
        height: 33px;
    }

    .pause-end-container {
        & > * {
            display: flex;
            width: 100%;

            & .pause-button {
                max-width: 35px;
                min-height: 32px;
                margin-right: 10px;
            }
        }
    }

    .sharing-active {
        button {
            background: $btn-control-default-hover;
            opacity: 0.5;
        }
    }
}
