#admin-panel-container {

    .admin-date {
        margin-left: 20px;
        font-weight: bold;
    }

    font-family: 'Roboto' !important;
    & input {
        font-family: 'Roboto' !important;
    }

    & > header {
        margin: 0;
        padding: 0;
        border-radius: 5px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
        border: solid 2px #727276;
        background: linear-gradient(to bottom, #414141 2%, #000000);
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        & button {
            z-index: 2;
            color: #fff;
            opacity: 1 !important;
            font-size: 17px;
            height: 60px;
        }
    }

    & span[class^="PrivateTabIndicator"] {
        height: 100% !important;
        background: linear-gradient(to bottom, #646464 1%, #373737);
    }

    #admin-panel-user-info {
        position: absolute;
        top: 0;
        right: 0;
        color: #dee1e3;
        font-size: 17px;
        font-weight: bold;
        display: flex;
        height: 60px;
        align-items: center;
        padding-right: 46px;

        & a {
            color: #dee1e3;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .MuiListItemText-primary {
        font-size: 17px;
        font-weight: bold;
        text-decoration: underline;
        color: #0d4171;
    }

    .MuiListItem-container {
        border-bottom: 1px solid #87c9e5;
    }
    
    .MuiListItem-container {
        padding: 18px 9px 18px 10px;
        &.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
            box-shadow: 0 2px 4px 0 #aeaeb0;
            background-color: #fff !important;
            cursor: pointer;
            border-bottom: 1px solid transparent;
        }
    }

    .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
        background-color: #fff !important;
    }

    .MuiListItem-root:hover {
        box-shadow: 0 2px 4px 0 #aeaeb0;
        background-color: #fff !important;
        cursor: pointer;
        border-bottom: 1px solid transparent;
    }

    .admin-form-container {
        & .input {
            height: 44px;
            padding: 0px 23px;
            border-radius: 5px;
            border: solid 2px #aeaeb0;
            background-color: #fff;
            font-size: 21px;
            font-weight: 500;
            color: #000;
            width: 100%;
            box-sizing: border-box;
            outline: 0;

            &.participants {
                width: 40px;
                text-align: center;
                padding: 0;
                height: 36px;
                font-size: 14px;
                font-weight: normal;
            }
        }
    }

    .react-datepicker-wrapper input {
            height: 36px;
            padding: 0px 23px;
            border-radius: 5px;
            border: solid 2px #aeaeb0;
            background-color: #fff;
            font-size: 21px;
            font-weight: normal;
            color: #000;
            width: 100%;
            box-sizing: border-box;
            font-size: 14px;
            outline: 0;
            text-align: center;
    }

    .two-columns {
        display: flex;
        margin-top: 30px;
        & > :first-child {
            min-width: 161px;
            margin-right: 16px;
        }

        & .training-radio-buttons:first-child {
            padding-left: 0 !important;
        }

        &.date-container {
            display: flex;
            align-items: center;
        }
    }

    .span-header {
        font-size: 15px;
        color: #000000;
    }

    .span-subheader {
        font-size: 13px;
        color: #57575a;
    }

    .right-align {
        text-align: right;
    }
}