.game-component-container {
    .grid-item {
        margin: 3px;
        &:first-of-type {
            margin-left: 6px;
        }
        &:last-of-type {
            margin-right: 6px;
        }
    }

    .row-grid {
        &:first-of-type {
            margin-top: 3px;
        }
        &:last-of-type {
            margin-bottom: 3px;
        }
    }

    .game-wrapper {
        padding-bottom: 50px;
        position: absolute;
        left: 22px;
        right: 0;
        margin: auto;
        min-height: 550px;
        top: 20px;
        width: 623px;

        &::after {
            content: '';
            background: url("/icons/BoardShadow.png") no-repeat left center;
            background-size: contain;
            height: 50px;
            bottom: 0;
            left: 0;
            width: 100%;
            position: absolute;
        }
    }

    @media screen and (max-height: 850px) {
        .game-wrapper {
            width: 612px;
        }
    }

    @media screen and (max-height: 650px) {
        .game-wrapper {
            &::after {
                bottom: 75px;
                background-position: center center;
                background-size: 50%;
            }
        }
        .game-num-top {
            width: 80px;
            transform: translateY(-6px);
            font-size: 24px;
        }
        .game-num-left {
            height: 80px;
            transform: translateX(-6px);
            font-size: 24px;
        }
    }
}

.countdown-container {
    // Do not change this zIndex because the financial report needs to be
    // above the countdown when opened
    z-index: 9999;
    width: 280px;
    margin-bottom: 45px;
    // margin-top: -92px;
}