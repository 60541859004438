.main-dialog-ffa {
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.6);
    margin-top: 45px;
    height: 600px;
    background-color: #ffffff;
    width: 760px;
    margin: auto;
}

.financial-container {
    top: calc(50% - 29vh);
}

@media screen and (max-width:1366px) {
    .main-dialog-ffa {
        width: 680px;
        height: 570px;
    }
}

.force-field-analysis-row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .left {
        flex: 0 1 324px;
        max-width:324px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 61px;
        padding-top: 87px;

        .page-title,
        .final-team-container,
        .fin-report,
        .team-rules,
        .facilitator-div,
        .bottom-div1 {
            position: initial;
            padding: 0;
            margin: 0 ;
            width: 100%;
            max-width: 250px;
        }

        .page-title {
            margin-bottom: 13px;
            max-width: 318px;
            text-align: left;
        }

        .bottom-div1 {
            top: 0;
            margin-bottom: 30px;
            .team-name {
                text-align: left;
            }
        }

        .facilitator-div {
            height: auto;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .facilitator-span {
                margin-left: 0;
                font-size: 17px;
            }
            .drop {
                margin-left: 5px;
                width: 208px;
            }
            &.user {
                flex-direction: row;
                align-items: center;
                margin-bottom: 0;
                font-size: 16px;
                .label {
                    margin: 0;
                    display: inline-block;
                    #facilator-dropdown {
                        position: relative;
                        display: inline-block;
                        transform: none;
                        margin-left: 10px;
                        color: white;
                    }
                }
            }
        }

        .fin-report {
            transform: translateX(-3px);
            margin-bottom: 20px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > div:first-child {
                width: 100%;
            }
        }
        
        .team-rules {
            transform: translateX(-3px);
        }

        .table-container {
            margin-left: 0 !important;
            margin-top: -367px;
        }
    }
    .right {
        flex: 0 1 calc(100% - 324px);
        max-width: calc(100% - 324px);
        padding-top: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            transform: translateX(-22%);
        }
        .main-dialog-ffa {
            min-width: initial;
            max-width: 762px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 1440px) {
    .force-field-analysis-row {
        .right {
            .main-dialog-ffa {
                max-width: 725px;
            }
        }
    }
}

@media screen and (max-width: 1439px) {
    .force-field-analysis-row {
        .right {
            .main-dialog-ffa {
                max-width: 625px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .force-field-analysis-row {
        .right {
            .questions-wrapper {
                max-width: 625px;
            }
        }
    }
}