.chat-container {
    width: 0px;
    height: 100%;
    position: fixed;
    top: 0;
    overflow: hidden;
    z-index: 999999;
    background: #013d6d;
    color: #fff;
    right: 0px;
    transition: all 0.5s;
    font-size: 15px;
    &.chat-visible {
        right: 0;
        width: 280px;
        z-index: 99999999 !important;
    }

    & .sent-message-btn {
        position: relative;
        display: flex;
        width: 100%;
        transition: all 0.3s;
        &:hover {
            opacity: 0.7;
        }
        & span {
            color: white !important;
        }
    }

    .chat-footer {
        bottom: 0;
        position: absolute;
        background-color: #013d6d;
        margin: 0;
        padding: 20px 10px 10px 10px;
        border-top: 1px solid #085898;
        box-shadow: 0 -10px 20px -9px #085898;
        box-sizing: border-box;
        textarea {
            height: 100px !important;
            font-size: 15px;
            overflow: hidden;
            width: 100%;
            resize: none;
            box-sizing: border-box;
            outline: 0;
            border-radius: 3px;
            padding: 5px;
        }
    } 

    & ul {
        list-style: none;
        margin: 0;
        padding: 15px;
        overflow: auto;
        min-width: 240px;
        max-height: calc(100% - 233px);
        & li {
            position: relative;
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: self-start;

            & button {
                position: absolute;
                right: 30px;
                top: 3px;
                cursor: pointer;
                width: 16px;
                border: 0;
                outline: 0;
                height: 16px;
                display: inline-block;
                background: url(/icons/mic-enabled.svg) no-repeat center center;
                background-size: 16px 16px;
                &:hover {
                    background: url(/icons/mic-enabled-hover.svg) no-repeat center center;
                    background-size: 16px 16px;
                }
                &.mutted {
                    background: url(/icons/mic-disabled-hover.svg) no-repeat center center;
                    background-size: 16px 16px;
                }
            }
        }
    }

    & span {
        color: #caccce
    }
    & > div {
        width: 280px;
        text-align: center;
        margin: 7px;
    }

    .message-container {
        p {
            margin-top: 0;
            margin-bottom: 5px;
        }

        .username {
            color: #f5e02e;
        }
    }
}
