// Buttons colors
$btn-control-default: #fff;
$btn-control-default-hover: #CCD8E1;
$txt-control-default: #edf0f2;
$txt-control-default-hover: #edf0f2;

$btn-control-primary: #62b2cf;
$btn-control-primary-hover: #62b2cf;
$txt-control-primary-color: #000;
$txt-control-primary-color-hover: #000;

$btn-control-secondary: #17609b;
$btn-control-secondary-hover: #17609b;
$txt-control-secondary-color: #fff;
$txt-control-secondary-color-hover: #fff;

$btn-control-contrast: #000;
$btn-control-contrast-hover: #363636;
$txt-control-contrast-color: #c3c5c7;
$txt-control-contrast-color-hover: #c3c5c7;
// END Buttons colors