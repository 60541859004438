.debrief-container {
    width: 100%;
    margin: 0 13px 3px 7px;
    
    & > * {
        display: inline-block !important;
    }
    & textarea {
        width: 100%;
        padding: 15px 10px;
        margin-bottom: 20px;
        border-radius: 3px;
        border: solid 2px #c3c5c7;
        box-sizing: border-box;
        height: 100px !important;
    }
}