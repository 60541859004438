.result-styles {
    background: url(/icons/main-background-new-design-rvm.png) no-repeat bottom center, linear-gradient(to bottom, rgb(0, 68, 130), rgb(25, 113, 162) 100%);
    background-size: contain;
    text-align: center;
    background-position: 100% 108%;

    .first-backgr {
        background: url("/icons/LadderNew.svg") no-repeat center 6px;
        width: 442px;
        height: 297px;
        position: absolute;
        bottom: calc(20% - -56px);
        left: calc(36% - 372px);
        z-index: 9999;
    }

    .team1Cont {
        position: absolute;
        width: 100px;
        height: 100px;
        color: white;
        bottom: calc(20% - -347px);
        left: calc(36% - 246px);
        font-size: 19px;
        font-weight: bold;
    }

    .team2Cont {
        position: absolute;
        width: 100px;
        height: 100px;
        bottom: calc(22% - -194px);
        left: calc(36% - 362px);
        color: white;
        font-size: 19px;
        font-weight: bold;
    }

    .team3Cont {
        color: white;
        width: 63px;
        bottom: calc(17% - -130px);
        left: calc(36% - 127px);
        height: 100px;
        position: absolute;
        font-size: 19px;
        font-weight: bold;
    }

    .team4Cont {
        color: white;
        width: 100px;
        bottom: calc(20% - -106px);
        left: calc(36% - 28px);
        position: absolute;
        font-size: 19px;
        font-weight: bold;
    }

    .team1Badge {
        width: 53px;
        height: 60px;
        position: absolute;
        margin-top: -4px;
    }
    
    .team2Badge {
        width: 53px;
        height: 60px;
        position: absolute;
        margin-top: -63px;
        margin-left: 24px !important;
    }
    
    .team3Badge {
        width: 53px;
        height: 60px;
        position: absolute;
        margin-top: -62px;
        margin-left: 17px !important;
    }

    .table-final {
        margin: 0 !important;
    }

    .fin-report {
        position: absolute;
        width: 655px;
        height: 309px;
        left: calc(50% - 369px);
        bottom: 23%;
        background: transparent;
        box-shadow: none;
    }
}

@media screen and (min-height: 1000px) {
    .team3Cont {
        bottom: calc(18% - -130px) !important;
    }
}

@media screen and (max-width: 2000px) {
    .result-styles {
        .top-name {
            bottom: auto;
            height: 40px;
            top: 180px;
        }
    }
}

@media screen and (max-width: 1440px) {
    .result-styles {
        .top-name {
            bottom: 570px;
            height: 40px;
            top: auto;
        }
        .fin-report {
            bottom: 17%;
        }
        .first-backgr {
            bottom: calc(13% - -56px);
        }
        .team2Cont {
            bottom: calc(17% - -194px);
        }
        .team1Cont {
            bottom: calc(13% - -347px);
        }
        .team3Cont {
            bottom: calc(10% - -130px);
        }
    }
}