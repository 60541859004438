.container-rm {
    background-color: #013d6d;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
}
.form-control-dummy-rm {
    margin: 15px !important;
    min-width: 120px !important;
    & .MuiInputBase-root {
        color: white !important;
    }
}
.selectEmpty {
    margin-top: 150px;
}
.button-add {
    margin-top: 1px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: white !important;
}

.button1 {
    min-width: 126px !important;
    height: 44px !important;
    padding: 6px 12px 6px 13px;
    border-radius: 5px;
    border: solid 2px #296daa;
    background-image: linear-gradient(to bottom, rgb(83, 166, 213), #296daa);
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.68;
    text-align: center;
    text-transform: inherit;
    color: white !important;
    font-weight: bold;
    margin-top: 20px !important;
    float: right;
}
.button2 {
    min-width: 128px;
    height: 32px;
    padding: 6px 6px 5px 8px;
    border-radius: 5px;
    border: solid 2px #aeaeb0;
    background-image: linear-gradient(to bottom, rgb(237, 240, 242), #aeaeb0);
    text-transform: inherit;
    color: #1b1c20;
    font-weight: bold;
}
.btn-container {
    margin-top: 30px;
}