.table-container {
    background-color:  #296daa;
    max-height: 330px;
    overflow-y: auto;
    margin-top: -354px;
    position: absolute;
    z-index: 9999;
    display: none;
    width: 652px;
    margin-left: -126px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
}

.table-final {
    margin-top: -10px !important;
    margin-left: -351px !important;
}

.table-container-open {
    display: inline;
    z-index: 9999 !important
}

.container-financial-st {
    width: 250px;
    margin-bottom: 10px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
    background-color: #feeda9;
    box-sizing: border-box;
    padding: 10px 0;
    position: relative;
    margin-top: 30px;
    z-index: 0;
    &.open {
        border-color: #feeda9;
        height: 40px;
        & *[class*="makeStyles-iconFinancialExpand"] {
            transform: rotate(90deg);
            margin-right: 6px;
        }
        z-index: 10000;
    }
}

.container-disabled {
    background-image: linear-gradient(to top, rgba(98, 178, 207, 0.5), rgba(135, 201, 229, 0.2));
}

.final-team-container {
    position: absolute;
    display: inline-block;
    left: 16px;
    margin-top: calc(39vh - 10px);
}