.top-name {
    top: 15px;
    left: calc(50% - 96px);
    color: white;
    position: absolute;
    font-family: Roboto;
    font-size: 36px;
    font-weight: bold;
}

.root-debrief {

    .screen-share {
        width: 48%;
        height: 70%;
        position: relative;
        left: 46%;
        top: calc(50% - 289px);
        border-radius: 3px;
    }

    .background-share {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.6);
        background-color: rgba(250, 250, 250, 1);
        width: 45%;
        height: 75%;
        position: absolute;
        left: calc(50% - 185px);
        top: 85px;
        border-radius: 10px;
        padding-top: 40px;
        z-index: 1000;
    }

    .question-next {
        width: 30px !important;
        height: 64px !important;
        position: absolute;
        z-index: 999;
        right: 28px;
        top: 39px;

        & button {
            cursor: pointer;
            border: 0;
            outline: 0;
            width: 41px;
            height: 41px;
            background: url("/icons/yellow-arrow-right.svg") no-repeat center center;
            &:hover {
                background: url("/icons/yellow-arrow-right-hover.png") no-repeat center center;
            }
        }
    }

    .question-prev {
        width: 30px !important;
        height: 64px !important;
        z-index: 999;
        position: absolute;
        z-index: 999;
        margin-left: 28px;
        top: 39px;

        & button {
            cursor: pointer;
            border: 0;
            outline: 0;
            width: 41px;
            height: 41px;
            background: url("/icons/yellow-arrow-right.svg") no-repeat center center;
            transform: rotate(180deg);
            &:hover {
                background: url("/icons/yellow-arrow-right-hover.png") no-repeat center center;
            }
        }
    }

    .main-question-styles {
        font-size: 17px;
        text-align: center;
        font-family: Roboto;
        margin-left: 50px;
        margin-right: 93px;
        margin-top: 13px;
        position: relative;
    }

    .table-container {
        margin: 0 auto !important;
        padding: 0 20px !important;
        display: block !important;
        background-color: initial !important;
        position: initial !important;
        width: auto !important;
        margin-left: 0 !important;
        box-shadow: none;


        & .MuiPaper-root.MuiTableContainer-root {
            width: 100%;
            box-shadow: none;
            background: transparent;
            border-bottom: none !important;
        }

        .table {
            margin-top: 34px;
            width: 100% !important;

            & tr:nth-child(even) {
                background-color: #edf0f2;
            }
        }
    }


}

@media screen and (min-width: 1701px) and (max-width: 1999px) {
    .question-prev {
        left: -20px !important;
    }
    .question-next {
        right: 11px !important;
    }
}

@media screen and (min-width: 1500px) and (max-width: 1700px) {
    .table-container {
        max-width: 698px !important;
    }
    .screen-share {
        left: 44% !important;
    }
}
@media screen and (min-width: 1300px) and (max-width: 1499px) {
    .table-container {
        max-width: 698px !important;
        margin-left: 26px;
    }
    .screen-share {
        left: 42% !important;;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1366px) {
    .table-container {
        max-width: 588px !important;
        margin-left: 26px;
    }
    .root-debrief .screen-share {
        left: calc(44% - 20px) !important;
    }
    .screen-share {
        left: 40% !important;;
    }
}

@media screen and (min-width: 2000px) {
    .table-container {
        margin-left: 20px;
        max-width: 698px !important;
    }
}

@media screen and (max-height: 600px) {
    .background-share {
        height: 57% !important;
    }
}

.video-container {
    height: 60vh;
    position: fixed;
    top: 100px;
    left: 0;
    padding: 5px 0;
    box-sizing: border-box;
    width: calc(50% - 200px);

    & video {
        height: 96px;
        width: 159px;
        object-fit: cover;
    }

    & #video-container {
        left: 0;
        flex-wrap: wrap;
        margin: 0px 2%;
        padding: 5px 0;
        overflow-x: hidden;
        overflow-y: hidden;
        box-sizing: border-box;
        display: flex;
        & > div {
            border-radius: 3px;
            border: 2px solid#558EA6 !important;
            height: 100px;
            width: 163px;
            box-sizing: border-box;
            margin-bottom: 10px;
        }
        & > div:not(:last-child) {
            margin-right: 5px;
        }
    }
}

.app-background {
    height: 102%;
    text-align: center;
    background: url(/icons/main-background-new-design-rvm.png) no-repeat bottom center, linear-gradient(to bottom, rgb(0, 68, 130), rgb(25, 113, 162) 100%);
    background-size: contain;
}

@media screen and (max-width: 1440px) {
    .root-debrief .background-share {
        width: 42%;
    }
}