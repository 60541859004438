.dialog-classes {
  z-index: 10000 !important;
}
.blockdiv {
  display: block !important;
}

.redirect-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 21px;
  font-family: Roboto;
  font-weight: 500;
}
