.team-name-container {
    text-align: left;
    & > * {
        color: white;
        font-size: 17px;
        font-weight: 500;
    }
}

.in-service-cont1 {
    margin-top: -40px;
}