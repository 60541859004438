.facilitator-div {
    font-size: 14px;
    line-height: 40px;
    color: white;
    left: 11px;
    & #demo-simple-select-label.MuiFormLabel-filled {
        display: none;
    }
    & .MuiInputLabel-formControl {
        z-index: 9999;
    }
    & .MuiInput-underline:before {
        border-bottom: unset !important;
        transition: unset !important;
    }
    & .MuiInput-underline:after {
        border-bottom: unset !important;
        transition: unset !important;
    }
    & .MuiFormLabel-root.Mui-focused {
        color: unset !important;
    }
    & .MuiInputBase-input {
        animation-name: unset !important;
        animation-duration: unset !important;
    }
    & .Mui-selected {
        background-color: #f5e02e;
    }
}

.button-show-map {
    position: absolute !important;
    border: solid 2px #87c9e5 !important;
    cursor: pointer;
    height: 40px;
    padding: 0 15px !important;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
    box-sizing: border-box !important;
    margin-top: 20px !important;
    transition: all 0.5s !important;
    border-radius: 5px !important;
    background-image: linear-gradient(to top, rgba(98, 178, 207, 1), rgba(135, 201, 229, 1) 72%);
    color: #1b1c20 !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: bold !important;
    justify-content: left !important;
    width: 252px;
    left: 16px !important;
    margin-top: calc(39vh - 68px) !important;
    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 15px;
        transition: transform 0.2s;
        display: inline-block;
        transform: rotate(90deg);
        background-image: url(/icons/blue-arrow-up.svg);
        width: 12px;
        height: 8px;
    }
}

.final-discussion-row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .left {
        flex: 0 1 324px;
        max-width:324px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 24px;
        padding-top: 87px;

        .page-title1,
        .final-team-container,
        .fin-report,
        .button-show-map,
        .team-rules,
        .team-rules-final-page,
        .facilitator-div,
        .bottom-div2 {
            position: initial;
            padding: 0;
            margin: 0 ;
            width: 100%;
            max-width: 250px;
        }

        .fin-report {
            .table-container-open {
                margin-left: 0 !important;
                margin-top: -326px;
            }
        }

        .team-rules {
            .up {
                position: absolute !important;
                left: 24px !important;
                transform: translateY(-281px) !important;

                @media screen and(max-height: 940px){
                    transform: translateY(-231px) !important;
                }

                @media screen and(max-width: 1680px){
                    transform: translateY(-231px) !important;
                }
            }
        }

        .button-show-map {
            position: relative !important;
            padding: 0 !important;
            margin-top: 0 !important;
            left: 0 !important;
            margin-bottom: 18px;
            display: flex;
            padding-left: 16px !important;
            align-items: center;
        }

        .page-title1 {
            margin-bottom: 45px;
            max-width: 318px;
        }

        .bottom-div2 {
            top: 0;
            margin-bottom: 23px;
            .team-name {
                text-align: left;
            }
        }

        .facilitator-div {
            height: auto;
            margin-bottom: 19px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .drop {
                margin-left: 5px;
                width: 208px;
            }
            &.user {
                flex-direction: row;
                align-items: center;
                margin-bottom: 0;
                font-size: 16px;
                .label {
                    margin: 0;
                    display: inline-block;
                    label {
                        position: relative;
                        display: inline-block;
                        transform: none;
                        margin-left: 10px;
                        color: white;
                    }
                }
            }
        }

        .final-team-container {
            margin-bottom: 18px;
            display: flex;
            & > div {
                &:first-child {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .right {
        flex: 0 1 calc(100% - 324px);
        max-width: calc(100% - 324px);
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            transform: translateX(-17%);
        }
        .questions-wrapper {
            min-width: initial;
            max-width: 925px;
        }
    }
}

@media screen and (max-width: 1440px) {
    .final-discussion-row {
        .right {
            .questions-wrapper {
                max-width: 725px;
            }
        }
    }
}

@media screen and (max-width: 1439px) {
    .final-discussion-row {
        .left {
            padding-top: 40px;
            .page-title1 {
                margin-bottom: 18px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .final-discussion-row {
        .right {
            .questions-wrapper {
                max-width: 625px;
            }
        }
    }
}

.game-component-container {
    .team-rules {
        .up {
            position: absolute !important;
            left: 28px !important;
            transform: translateY(-281px) !important;

            @media screen and(max-height: 940px){
                transform: translateY(-231px) !important;
            }

            @media screen and(max-width: 1680px){
                transform: translateY(-231px) !important;
            }
        }
    }
}