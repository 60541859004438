.admin-form-container {
  position: relative;
  padding-bottom: 60px;

  .training-radio-buttons {
    color: #57575a !important;
    padding: 0 9px !important;
    &:hover {
      background-color: unset !important;
      color: rgba(87, 87, 90, 0.7) !important;
    }
  }

  .remove-debrief {
    cursor: pointer;
    background: url(/icons/admin/calendar-icon.svg) no-repeat center center;
    width: 20px;
    height: 20px;
  }

  .react-datepicker-popper {
    z-index: 199 !important;
  }

  .calendar-icon {
    outline: 0;
    margin-left: 20px;
    margin-right: 16px;
    border-radius: 5px;
    border: solid 2px #aeaeb0;
    width: 41px;
    height: 32px;
    cursor: pointer;
    background: url(/icons/admin/calendar-icon.svg) no-repeat center center,
      linear-gradient(to bottom, #edf0f2, #aeaeb0);
    &:hover {
      background: url(/icons/admin/calendar-icon.svg) no-repeat center center,
        linear-gradient(to bottom, #aeaeb0, #edf0f2);
    }
  }

  .input-date input {
    max-width: 110px;
    padding: 0 !important;
  }

  .input-time {
    .react-datepicker-wrapper {
      margin-left: 14px;
    }

    input {
      padding: 0 !important;
      max-width: 90px;
    }
  }

  .debrief-row {
    display: flex;
    margin-right: 20px;

    & > span {
      font-size: 17px;
      font-weight: 500;
      margin: 1px 7px 4px 0;
    }

    & > button {
      width: 21px;
      height: 21px;
      cursor: pointer;
      display: inline;
      border: 0;
      outline: 0;
      background: url(/icons/admin/remove.svg) no-repeat center center;
      &:hover {
        background-image: url(/icons/admin/remove-hover.svg);
      }
    }
  }

  .debrief-add-question {
    cursor: pointer;
    display: inline;
    border: 0;
    outline: 0;
    padding-left: 30px;
    margin-bottom: 30px;
    min-height: 21px;
    margin-top: 10px;
    font-size: 15px;
    color: #0d4171;
    text-decoration: underline;
    background: url(/icons/admin/add.svg) no-repeat left center;
    &:hover {
      background-image: url(/icons/admin/add-hover.svg);
    }
  }

  .debrief-copy-link {
    background-color: #dff5fe;
    border: 0;
    border-radius: 0;
    font-size: 16px;

    & span {
      font-weight: bold;
    }
  }

  .training-copy-btn {
    width: 108px;
    height: 32px;
    cursor: pointer;
    outline: 0;
    padding: 6px 6px 5px 8px;
    border-radius: 5px;
    border: solid 2px #aeaeb0;
    background-image: linear-gradient(to bottom, #edf0f2, #aeaeb0);

    &:hover {
      background-image: linear-gradient(to bottom, #aeaeb0, #edf0f2);
    }
  }

  .teamMembers {
    font-size: 16px;
    margin-right: 15px;
    font-weight: bold;
  }

  .index-groups {
    margin-left: -6px;
    margin-right: 5px;
  }

  .action-buttons-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    border-top: 2px solid #dcdcdc;
    padding: 8px 0;
    width: calc(100% + 32px);
    margin-left: -16px;
    bottom: -24px;

    & > :not(:last-child) {
      margin-right: 17px;
    }
  }

  .active-button {
    font-size: 19px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    outline: 0;
    padding: 6px 12px 6px 13px;
    border-radius: 5px;
    border: solid 2px #296daa;
    background-image: linear-gradient(to bottom, #53a6d5, #296daa);

    &:hover {
      background-image: linear-gradient(to bottom, #296daa, #53a6d5);
    }
  }

  .inactive-button {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    outline: 0;
    padding: 6px 20px;
    color: #aeaeb0;
    border-radius: 5px;
    border: solid 2px #d3d3d3;
    background-color: #fff;
    max-height: 32px;
  }
}
