.grid-item {
    margin: 3px !important ;
    &:first-of-type {
        margin-left: 6px !important;
    }
    &:last-of-type {
        margin-right: 6px !important;
    }
}

.row-grid {
    &:first-of-type {
        margin-top: 3px;
    }
    &:last-of-type {
        margin-bottom: 3px;
    }
}

.game-wrapper {
    padding-bottom: 50px;
    position: absolute;
    left: 22px;
    right: 0;
    margin: auto;
    min-height: 550px;
    top: 20px;
    width: 623px;

    &::after {
        content: '';
        background: url("/icons/BoardShadow.png") no-repeat left center;
        background-size: contain;
        height: 50px;
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
    }
}

@media screen and (max-height: 850px) {
    .game-wrapper {
        width: 605px;
    }
}
