.accordion-container {
    .accordion-header {
        position: relative;
        cursor: pointer;
        margin: 16px 0;
        padding: 12px 266px 12px 16px;
        border-radius: 3px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
        border: solid 1px #e2e2e2;
        background-image: linear-gradient(to top, #ddf0f8, #fff);
        font-size: 17px;
        font-weight: bold;
        color: #0d4171;

        &::after {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            right: 16px;
            top: calc(50% - 8px);
            transition: all 0.2s ease-out;
            background: url(/icons/blue-arrow-up.svg) no-repeat center center,;
            transform: rotate(0);
        }

        &.open {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .accordion-content {
        transition: all 0.2s ease-out;
        height: 0;
        overflow: hidden;

        &.open {
            height: 300px;
            overflow: auto !important;
        }
    }
}