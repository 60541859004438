.main-container-rules {
    cursor: pointer;
    transition: all 0.5s;
    & .textarea-container {
        display: none;
    };
    &.open {
        height: 36px;
        width: 250px;
        z-index: 6000;
        transition: all 0.5s;
        & .textarea-container {
            display: inline-block;
        }
        &::after {
            transform: rotate(270deg);
            top: 10px;
            right: 16px;
        }
        &.up {
            & *[class*=textAreaDiv] {
                transform: translateY(-282px);
                box-shadow: none
            }
        }
    }
}

.team-rules-final-page {
    position: absolute;
    left: 16px;
    top: calc(39vh - -239px);
}